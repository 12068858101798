<template>
  <Popup :width="width" :margin="margin" :radiusType="radiusType" :closePopupEvent="closePopup">
    <div class="confirmation" slot="component">
      <div :dir="localDisplayLang === 'ara' ? 'rtl' : 'ltr'" class="confirmation-title">
       {{$t("Are you sure you want to delete")}}
        <span>{{ deleteInfo.profilename }}</span
        >
      </div>
      <div class="form-group">
        <div v-if="formError" class="error-container">
          <p class="error">{{ formError }}</p>
        </div>
      </div>
      <div class="confirmation-btn">
        <button
          class="button-primary confirmation-btn-align"
          :class="[providerUniqueId === 'z01x1wPo' ? 'primary-nammaflix-button' : 'primary-net5-button']"
          @click="approveDeleteProfile"
        >
          {{$t("ok")}}
        </button>
        <button
          class="button-primary confirmation-btn-align"
          :class="[providerUniqueId === 'z01x1wPo' ? 'primary-nammaflix-button' : 'primary-net5-button']"
          @click="decline"
        >
          {{$t("cancel")}}
        </button>
      </div>

      <br v-if="isLoading"/>

      <div v-if="isLoading"><Loading /></div>
    </div>
  </Popup>
</template>

<script>
import googleAnalytics from "@/mixins/googleAnalytics.js";
import { _providerId } from "@/provider-config.js";
import { mapActions } from "vuex";

import { showSnackBar } from '@/utilities';

export default {
  props: {
    closePopup: {
      type: Function,
    },
    deleteInfo: {
      type: Object,
    },
  },
  data() {
    return {
      width: "30%",
      margin: "15% auto",
      radiusType: "full",
      formError: null,
      providerUniqueId: _providerId,
      isLoading: false,
      localDisplayLang: null
    };
  },
  watch: {
    formError(val) {
      if (val) {
        setTimeout(() => {
          this.formError = null;
        }, 5000);
      }
    },
  },
  mounted() {
    // eventBus.$on("deleteprofile-response", response => {
    //   console.log("delete response", response);
    //   let GA = {};
    //   if (response.status.reason) {
    //     this.formError = response.reason;
    //   } else {
    //     //logging delete profile google analytics event.
    //     GA.deleted_profile_id = response.deletePayload;
    //     this.deleteProfileEvent(GA);
    //     this.$emit("deleteConfirm");
    //     this.closePopup();
    //   }
    // });

    this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");
  },
  methods: {
    ...mapActions(["deleteProfile"]),

    approveDeleteProfile() {

      this.isLoading = true;
      // eventBus.$emit("deleteProfile", this.deleteInfo.profileid);
      // console.log("THE PROFILE TO BE DELETED -- > ", profile);

      const payload = {
        profileid: this.deleteInfo.profileid,
      };

      this.deleteProfile(payload)
        .then((response) => {
          console.log("THE RESPONSE -- > ", response);

          if (response.data.success) {
            this.$emit("refreshProfilesList", true);
            this.isLoading = false;
            this.closePopup();
          } else if (response.data.reason) {
            // snackbar.show({
            //   text: this.$t(response.data.reason),
            //   textColor: "#ffffff",
            //   pos: "bottom-center",
            //   actionTextColor: "#de2600",
            // });
            showSnackBar(this.$t(response.data.reason));
            this.isLoading = false;
            this.closePopup();
          }
        })
        .catch((error) => {
          console.log("unable to delete profile", error);
        });
    },
    decline() {
      this.closePopup();
    },
  },
  components: {
    Popup: () => import(/* webpackChunkName: "Popup" */ "@/components/Slots/Popup.vue"),
    Loading: () => import(/* webpackChunkName: "Loading" */ "@/components/Templates/Loading.vue"),
  },
  mixins: [googleAnalytics],
};
</script>

<style lang="scss">
@import "@/sass/_variables.scss";
@import "@/sass/_components.scss";
@import "./confirmation.scss"
</style>
